import React, { useState } from "react";
import logo from "../Images/logo.png";
import { TiLockClosed } from "react-icons/ti";
import { BiSolidUser } from "react-icons/bi";
import { AiFillEyeInvisible } from "react-icons/ai";
import { AiFillEye } from "react-icons/ai";
import { CgSpinnerTwo } from "react-icons/cg";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import baseUrl from "../BaseUrl";

function Login() {
  const navigate = useNavigate("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData(event.target);

    const formDataObject = {};
    formData.forEach((value, key) => {
      formDataObject[key] = value;
    });
    fetch(`${baseUrl}/api-token-auth/login/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formDataObject),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.error) {
          toast.error(res.error, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            style: {
              backgroundColor: "#22272c",
              color: "white",
            },
          });
        } else if (res.token && res.user.role === "student") {
          localStorage.setItem(
            "userData",
            JSON.stringify({
              userData: res.user,
              time: new Date(),
            })
          );
          localStorage.setItem("token", JSON.stringify(res.token));
          navigate("/dashboard", { replace: true });
          toast.success("Login success", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            style: {
              backgroundColor: "#22272c",
              color: "white",
            },
          });
        } else if (res.token && res.user.role === "admin") {
          localStorage.setItem(
            "adminData",
            JSON.stringify({
              adminData: res.user,
              time: new Date(),
            })
          );
          localStorage.setItem("tokenAdmin", JSON.stringify(res.token));
          navigate("/admin", { replace: true });
          toast.success("Login success", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            style: {
              backgroundColor: "#22272c",
              color: "white",
            },
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error) {
          toast.error("Check Your Internet Connection and Try again.", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            style: {
              backgroundColor: "#22272c",
              color: "white",
            },
          });
        }
      })
      .finally((error) => {
        setLoading(false);
        if (error) {
          toast.error("Something Went Wrong!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            style: {
              backgroundColor: "#22272c",
              color: "white",
            },
          });
        }
      });
  };
  return (
    <div className="flex h-screen w-full items-center justify-center bg-light-primary dark:bg-dark-primary bg-cover bg-no-repeat">
      <div className="rounded-xl w-11/12 md:w-3/6 bg-light-secondary dark:bg-dark-secondary bg-opacity-50 px-2 md:px-10 py-4 md:py-10 shadow-lg backdrop-blur-md ">
        <div className="text-light-secondary_2 dark:text-dark-secondary_2">
          <div className="mb-8 flex flex-col items-center">
            <h1 className="mb-2 text-2xl text-light-secondary_2 dark:text-dark-secondary_2 uppercase font-semibold">
              Student Portal
            </h1>
            <img
              className="w-[120px] h-[120px] object-cover"
              src={logo}
              alt="logo"
            />
            <span className="text-light-secondary_2 dark:text-dark-secondary_2 font-semibold">
              Enter Login Details
            </span>
          </div>
          <form className="w-full" onSubmit={handleSubmit}>
            <div className="mb-4 w-full flex">
              <div className="w-full flex rounded-3xl items-center justify-start border-none bg-light-primary dark:bg-dark-primary bg-opacity-50 px-2 py-3  text-light-secondary_2 dark:text-dark-secondary_2 placeholder:text-sm shadow-lg outline-none backdrop-blur-md text-[16px]">
                <BiSolidUser className="text-[18px]" />
                <input
                  required
                  className=" flex-1 bg-transparent placeholder:capitalize h-full pl-2 placeholder:text-light-secondary_2 dark:text-dark-secondary_2 placeholder:text-sm outline-none "
                  type="text"
                  name="email_or_phone_or_username"
                  placeholder="Enter Your email/phone/username"
                />
              </div>
            </div>
            <div className="mb-4 w-full flex">
              <div className="w-full flex rounded-3xl items-center justify-start border-none bg-light-primary dark:bg-dark-primary bg-opacity-50 px-2 py-3  text-light-secondary_2 dark:text-dark-secondary_2 placeholder:text-sm shadow-lg outline-none backdrop-blur-md text-[16px]">
                <TiLockClosed className="text-[18px]" />
                <input
                  required
                  className=" flex-1 bg-transparent h-full pl-2 placeholder:text-light-secondary_2 dark:text-dark-secondary_2 placeholder:text-sm outline-none "
                  type={`${show ? 'text' : 'password'}`}
                  name="password"
                  placeholder="Enter Your Password"
                />
                {!show ? (
                  <AiFillEyeInvisible onClick={() => setShow(true)} className="text-[18px] cursor-pointer mr-1" />
                ) : (
                  <AiFillEye onClick={() => setShow(false)} className="text-[18px] cursor-pointer mr-1" />
                )}
              </div>
            </div>
            {loading ? (
              <div className="mt-8 flex justify-center text-lg">
                <button
                  type="submit"
                  className="rounded-3xl bg-light-primary dark:bg-dark-primary text-[27px] bg-opacity-50 px-16 py-3 capitalize font-bold shadow-xl backdrop-blur-md text-light-secondary_2 dark:text-dark-secondary_2 transition-colors duration-300  hover:bg-light-secondary dark:bg-dark-secondary"
                >
                  <CgSpinnerTwo className="spin" />
                </button>
              </div>
            ) : (
              <div className="mt-8 flex justify-center text-lg">
                <button
                  type="submit"
                  className="rounded-3xl bg-light-primary dark:bg-dark-primary bg-opacity-50 px-10 py-3 capitalize font-bold shadow-xl backdrop-blur-md text-light-secondary_2 dark:text-dark-secondary_2 transition-colors duration-300 hover:text-white hover:bg-light-secondary dark:bg-dark-secondary"
                >
                  Login
                </button>
              </div>
            )}
          </form>
          <a
            className="w-full py-2 px-2 text-center inline-block mt-2"
            href="/forgot_password"
          >
            Forgot Password?
          </a>
        </div>
      </div>
    </div>
  );
}

export default Login;
