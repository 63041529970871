import React from "react";
import { TbProgressCheck } from "react-icons/tb";
import { FcBusinessman } from "react-icons/fc";
import BarChart from "./AdminCharts/BarChart";
import LineChart from "./AdminCharts/LineChart";

function AdminDashboard({ courseData, mentors, students, studentPerCourse }) {  
  return (
    <div className="w-full min-h-full flex flex-col overflow-y-auto">
      <div className="w-full h-auto flex mt-3 items-center flex-col md:flex-row justify-evenly flex-wrap">
        <div className="w-[80%] md:w-[250px] mb-3 pt-3 lg:mb-0 shadow-lg h-max min-h-[150px] rounded-md bg-light-secondary_2 dark:bg-dark-secondary_2 flex flex-col items-center justify-evenly">
          <TbProgressCheck className="text-[40px] w-[45px] p-2 h-[45px] rounded-[50%] bg-light-black_color dark:bg-dark-text_color text-light-secondary dark:text-dark-secondary" />
          <p className="w-full py-2 px-1 text-center text-light-text_color dark:text-dark-text_color tracking-wider font-semibold">
            All Registered Students
          </p>
          <p className="w-full py-2 px-1 text-center text-light-text_color dark:text-dark-text_color tracking-wider font-bold">
            {students && students.length > 0 ? students.length : "0"}
          </p>
        </div>
        <div className="w-[80%] md:w-[250px] mb-3 pt-3 lg:mb-0 shadow-lg h-max min-h-[150px] rounded-md bg-light-secondary_2 dark:bg-dark-secondary_2 flex flex-col items-center justify-evenly">
          <svg
            className="w-[45px] p-2 h-[45px] rounded-[50%] bg-light-black_color dark:bg-dark-text_color"
            width="20"
            height="20"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M27 30H3C2.20459 29.9994 1.44199 29.7735 0.879557 29.3717C0.31712 28.97 0.000794215 28.4253 0 27.8571V2.14286C0.000794215 1.57471 0.31712 1.03 0.879557 0.628255C1.44199 0.226514 2.20459 0.000567296 3 0H27C27.7954 0.000567296 28.558 0.226514 29.1204 0.628255C29.6829 1.03 29.9992 1.57471 30 2.14286V19.9479L22.5 17.2693L15 19.9479V2.14286H3V27.8571H27V23.5714H30V27.8571C29.9988 28.4252 29.6824 28.9698 29.12 29.3714C28.5577 29.7731 27.7953 29.9991 27 30ZM22.5 14.8736L27 16.4807V2.14286H18V16.4807L22.5 14.8736Z"
              fill="#8BDC77"
            />
          </svg>
          <p className="w-full py-2 px-1 text-center text-light-text_color dark:text-dark-text_color tracking-wider font-semibold">
            Registered Course Units
          </p>
          <p className="w-full py-2 px-1 text-center text-light-text_color dark:text-dark-text_color capitalize tracking-wider font-bold">
            {courseData && courseData.length > 0 ? courseData.length : "0"}
          </p>
        </div>
        <div className="w-[80%] md:w-[250px]  mb-3 pt-3 lg:mb-0 shadow-lg  h-max min-h-[150px] rounded-md bg-light-secondary_2 dark:bg-dark-secondary_2 flex flex-col items-center justify-evenly">
          <FcBusinessman className="text-[40px] w-[45px] p-2 h-[45px] rounded-[50%] bg-light-black_color dark:bg-dark-text_color " />
          <p className="w-full py-2 px-1 text-center text-light-text_color dark:text-dark-text_color tracking-wider font-semibold">
            Technical Mentor
          </p>
          <p className="w-full py-2 px-1 text-center text-light-text_color dark:text-dark-text_color capitalize tracking-wider font-bold">
            {mentors && mentors.length > 0 ? mentors.length : "0"}
          </p>
        </div>
      </div>
      <div className="w-full flex flex-col lg:flex-row justify-between  flex-1 mt-2 py-2 px-4">
        <BarChart students={students} />
        <LineChart studentPerCourse={studentPerCourse} />
      </div>
    </div>
  );
}

export default AdminDashboard;
