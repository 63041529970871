import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Login from './Pages/Login';
import Signup from './Pages/Signup';
import ErrorPage from './Pages/ErrorPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dashboard from './Pages/Dashboard pages/Dashboard';
import Admin from './AdminPages/Admin';
import ForgotPassword from './Pages/ForgotPassword';

function App() {
  const navigate = useNavigate()

  const interval = setInterval(() => {
    userLogOut();
  }, 2000);

  function userLogOut(){
    const data = JSON.parse(localStorage.getItem('adminData') || localStorage.getItem('userData') || '{}');
    if(!Object.keys(data).length){
      return;
    }
    const loggedInTime =new Date(data.time);
    const now =  new Date();
    const timeDifference = now - loggedInTime;
    const timeDifferenceInMinutes = timeDifference / (1000 * 60)
    if(timeDifferenceInMinutes > 20){
      navigate('/', {replace: true});
      localStorage.removeItem('userData')
      localStorage.removeItem('adminData')
      clearInterval(interval);
    }    
  }


  
  return (
    <>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/dashboard/*' element={<Dashboard />} />        
        <Route path='/admin/*' element={<Admin/>} />        
        <Route path='/forgot_password' element={<ForgotPassword/>} />        
        <Route path='*' element={<ErrorPage />} />
      </Routes>
      <ToastContainer/>
    </>
  );  
}
export default App;
